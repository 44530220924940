import React from 'react';
import { ReactSVG } from 'react-svg';
import CalendarBtn from './CalendarBtn';
import bigCat from '../../assets/images/hero-big-cat.svg';
import bgEllipse from '../../assets/images/hero-ellipse.svg';
import bgCloud1 from '../../assets/images/hero-cloud1.svg';
import bgCloud2 from '../../assets/images/hero-cloud2.svg';
import bgCloud3 from '../../assets/images/hero-cloud3.svg';
import { useMemo } from "react";

import Home from "./Home";

import * as anchor from "@project-serum/anchor";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletWallet,
  getSolletExtensionWallet,
} from "@solana/wallet-adapter-wallets";

import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";

import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";
import { createTheme, ThemeProvider } from "@material-ui/core";

const treasury = new anchor.web3.PublicKey(
  process.env.REACT_APP_TREASURY_ADDRESS
);

const config = new anchor.web3.PublicKey(
  process.env.REACT_APP_CANDY_MACHINE_CONFIG
);

const candyMachineId = new anchor.web3.PublicKey(
  process.env.REACT_APP_CANDY_MACHINE_ID
);

const network = process.env.REACT_APP_SOLANA_NETWORK;

const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST;
const connection = new anchor.web3.Connection(rpcHost);

const startDateSeed = parseInt(process.env.REACT_APP_CANDY_START_DATE, 10);

const txTimeout = 30000; // milliseconds (confirm this works for your project)

const Hero = () => {
  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSlopeWallet(),
      getSolflareWallet(),
      getSolletWallet({ network }),
      getSolletExtensionWallet({ network }),
    ],
    [],
  );

  return (
    <div className="hero">
      <div className="hero__container">
        <div className="hero__background">
          <ReactSVG className="hero__background-ellipse" src={bgEllipse} />
          <ReactSVG className="hero__background-cloud1" src={bgCloud1} />
          <ReactSVG className="hero__background-cloud2" src={bgCloud2} />
          <ReactSVG className="hero__background-cloud3" src={bgCloud3} />
        </div>
        <div className="hero__information">
          <h1 className="hero__title">
            <span className="hero__title_red">Cute</span> is the new ‘
            <span className="hero__title_red">cool</span>’
          </h1>
          <p className="hero__text">
            Now you can own some of the cutest critters in the Metaverse.
          </p>
          <ThemeProvider>
            <ConnectionProvider endpoint={endpoint}>
              <WalletProvider wallets={wallets} autoConnect={true}>
                <WalletDialogProvider>
                  <Home
                    candyMachineId={candyMachineId}
                    config={config}
                    connection={connection}
                    startDate={startDateSeed}
                    treasury={treasury}
                    txTimeout={txTimeout}
                  />
                </WalletDialogProvider>
              </WalletProvider>
            </ConnectionProvider>
          </ThemeProvider>
          <ReactSVG className="hero__img" src={bigCat} />
        </div>
      </div>
    </div>
  );
};

export default Hero;
